
$primary: #2f5dc4 ;
$secondary: #E65508 ;
$input-active-color:#333333;
$input-inactive-color:#E3E3E3;
$input-error-color:#333333;

$input-active-bg: #ffffff;
$input-inactive-bg: #ffffff;
$input-error-bg: #ffdddd;
@import '../../app.scss'; 